<template>
  <div class="container py-5">
    <div class="row">
      <div class="col-md-4">
        <h4 class="text-dark f-bold">Specification</h4>
        <p class="text-dark" v-if="product.category !== 'package'">
          {{ product.description }}
        </p>
        <ul class="text-dark" v-if="product.category === 'package'">
          <li class="text-capitalize">
            <p>MPPT Controller : {{ product.components.MPPT_controller }}</p>
          </li>
          <li class="text-capitalize">
            <p>Inverter : {{ product.components.battery_inverter }}</p>
          </li>
          <li class="text-capitalize">
            <p>Lead batteery : {{ product.components.lead_battery }}</p>
          </li>
          <li class="text-capitalize">
            <p>Solar PV: {{ product.components.solar_PV }}</p>
          </li>
          <li class="text-capitalize">
            <p>VAT: {{ product.components.vat }}%</p>
          </li>
        </ul>
      </div>
      <div class="col-md-4" v-if="load > 0">
        <h4 class="text-dark f-bold">Recommended load</h4>

        <ul class="text-dark">
          <li
            class="text-capitalize"
            v-for="(load, index) in product.recommended_load"
            :key="index"
          >
            {{ `${load.load}` }} &#45;
            {{ `${load.quantity}pcs` }}
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <h4 class="text-dark f-bold">Delivery Location</h4>
        <p v-if="product.category === 'package'">
          For more details and delivery, please contact Imperium customer
          success officers on
          <a href="tel:+2348164133967">08164133967</a>,
          <a href="tel:+2347063025545">07063025545</a> or email
          <a href="mailto:info@imperiumng.com">info@imperiumng.com</a>
        </p>
        <ul class="locationList" v-else>
          <li
            class="text-dark"
            v-for="(location, index) in product.vendor_location"
            :key="index"
          >
            {{ location }}
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <h4 class="text-dark f-bold">Available stock</h4>
        <p class="text-dark">
          {{ product.stock ? product.stock.quantity_available : "" }} pcs
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["product"],
  computed: {
    load() {
      return this.product?.recommended_load?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: black;
}
.locationList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 1rem;
  li {
    font-size: 1rem;
    margin: 0 1rem;
  }
}
</style>
